import RouteName from "./models/ApiRouteInteface";

const baseApiRoute = process.env.REACT_APP_API_BASE_LINK;
const baseApiRouteAiDocumentChecker = process.env.REACT_APP_API_BASE_LINK_AI_DOCUMENT_CHECKER;

const apiRoutes: RouteName = {
    user: baseApiRoute + '/v1/auth/user',
    login: baseApiRoute + '/v1/auth/sign-in',
    register: baseApiRoute + '/v1/auth/sign-up',
    logout: baseApiRoute + '/v1/auth/sign-out',
    verifyToken: baseApiRoute + '/v1/auth/verify-token?token=TOKEN',
    verifyCui: baseApiRoute + '/v1/auth/check-cui?cui=',

    firms: baseApiRoute + '/v1/firm',
    firmsActions: baseApiRoute + '/v1/firm/actions',
    firmSubscription: baseApiRoute + '/v1/firm/actions/SLUG/subscriptions',
    firmSubscriptionStatus: baseApiRoute + '/v1/firm/actions/SLUG/subscriptions/APP_SLUG/status',
    marketOrder: baseApiRoute + '/v1/firm/actions/SLUG/market',
    firmMarketOrderScreen: baseApiRoute + '/v1/subscriptions/order-screen-for-firm',
    firmsPiece: baseApiRoute + '/v1/firm/SLUG',
    addToFirm: baseApiRoute + '/v1/firm/actions/SLUG/add-user',
    removeFromFirm: baseApiRoute + '/v1/firm/actions/SLUG/remove-user/SLUG_USER',
    searchEmployees: baseApiRoute + '/v1/firm/actions/SLUG/employees?query=',
    firmInvoices: baseApiRoute + '/v1/firm/actions/SLUG/invoices',
    firmPayments: baseApiRoute + '/v1/firm/actions/SLUG/payment-history',
    firmApps: baseApiRoute + '/v1/firm/actions/my-apps',
    firmUsage: baseApiRoute + '/v1/firm/actions/SLUG/usage',
    firmLogin: baseApiRoute + '/v1/firm/actions/SLUG/login-as-user',
    firmAllowApp: baseApiRoute + '/v1/firm/actions/FIRM_SLUG/employees/EMPLOYEE_SLUG/allow-app/APP_SLUG',
    firmAddLimit: baseApiRoute + '/v1/firm/actions/FIRM_SLUG/employees/EMPLOYEE_SLUG/app/APP_SLUG/limit',
    firmDisallowApp: baseApiRoute + '/v1/firm/actions/FIRM_SLUG/employees/EMPLOYEE_SLUG/disallow-app/APP_SLUG',
    firmEmployeesApp: baseApiRoute + '/v1/firm/actions/FIRM_SLUG/employees/app/APP_SLUG',
    createSubscription: baseApiRoute + '/v1/firm/actions/FIRM_SLUG/subscriptions',
    addCreditsToSubscription: baseApiRoute + '/v1/firm/actions/subscriptions/SUBSCRIPTION_SLUG/credits',

    subscriptions: baseApiRoute + '/v1/subscriptions',
    subscriptionsCheckoutSuccess: baseApiRoute + '/v1/subscriptions/checkout-success',
    aiDocumentCheckerSubscriptions: baseApiRoute + '/v1/subscriptions/ai-document-checker/list',
    aiDocumentCheckerCredits: baseApiRoute + '/v1/subscriptions/ai-document-checker/credits',

    users: baseApiRoute + '/v1/user',
    usersPending: baseApiRoute + '/v1/user/pending',
    usersPiece: baseApiRoute + '/v1/user/SLUG',
    usersAccept: baseApiRoute + '/v1/user/SLUG/accept',
    usersRefuse: baseApiRoute + '/v1/user/SLUG/refuse',

    getMyProviders: baseApiRoute + '/v1/providers/mine',
    getProviderDetails: baseApiRoute + '/v1/providers/CUI',
    deleteProvider: baseApiRoute + '/v1/providers/CUI',
    createProvider: baseApiRoute + '/v1/providers/create',
    addContactProvider: baseApiRoute + '/v1/providers/update/CUI/contact',
    editBaseDetails: baseApiRoute + '/v1/providers/update/CUI/base-details',
    searchGeneralData: baseApiRoute + '/v1/providers/search',
    changeProvider: baseApiRoute + '/v1/providers/change-provider',
    buildProvider: baseApiRoute + '/v1/providers/build-for-task/TASK_SLUG',

    apps: baseApiRoute + '/v1/apps'
};

const apiRoutesAiDocumentChecker: RouteName = {
    user: baseApiRouteAiDocumentChecker + '/firm/FIRM_SLUG/v1/auth/user',
    createTask: baseApiRouteAiDocumentChecker + '/firm/FIRM_SLUG/v1/tasks/analyse',

    updateOcrDetails: baseApiRouteAiDocumentChecker + '/firm/FIRM_SLUG/v1/tasks/TASK_SLUG/update-ocr',
    getTaskDetailsMinimal: baseApiRouteAiDocumentChecker + '/firm/FIRM_SLUG/v1/tasks/TASK_SLUG/minimal',
    getMyTasks: baseApiRouteAiDocumentChecker + '/firm/FIRM_SLUG/v1/tasks',
    viewDocument: baseApiRouteAiDocumentChecker + '/firm/FIRM_SLUG/v1/tasks/TASK_SLUG/document-repository/DOCUMENT_NAME',
    blacklistSearch: baseApiRouteAiDocumentChecker + '/firm/FIRM_SLUG/v1/blacklist/search',
}

export {apiRoutes, apiRoutesAiDocumentChecker};