import React, {useState} from "react";
import EducationContactDetails from "../../../models/EducationContactDetails";
import InsuranceCompany from "../../../models/InsuranceCompany";

export default function AiDocumentCheckerEducationInsurance() {
    const [educationDetails, setEducationDetails] = useState<EducationContactDetails | null>(null);
    const [selectedInsuranceCompany, setSelectedInsuranceCompany] = useState<string | null>(null);
    const insuranceCompanies: InsuranceCompany[] = [
        {
            name: "ASKO",
            slug: "askosrl",
            emailAddress: "ro-info@asko24.com",
            phoneNumber: "+40256211221",
            phoneDirections: "Spune: Doresc sa verific o asigurare CMR a unui colaborator",
        },
        {
            name: "Wirtschafts-Assekuranz München",
            slug: "wiassrl",
            emailAddress: "amb@wias.ro",
            phoneNumber: "+4996214930121",
            phoneDirections: null,
        },
        {
            name: "Unipol",
            slug: "Unipol",
            emailAddress: "contact@unipol.ro",
            phoneNumber: null,
            phoneDirections: null,
        }
    ];

    const handleEducationDetailsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setEducationDetails((prev) => ({
            ...prev,
            [name]: value
        }) as EducationContactDetails);
    }

    const handleCheckState = (name: string, value: boolean) => {
        setEducationDetails((prev) => ({
            ...prev,
            [name]: value
        }) as EducationContactDetails);
    }

    const handleProviderUiChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedInsuranceCompany(event.target.value);
    }

    const handleSendEmail = () => {
        const subject = "Rog validarea acestei polite";
        const body = "Buna ziua, va rog sa validati aceasta asigurare cu suma de " + (educationDetails?.amount ?? "") + " lei pentru numarul de inmatriculare " + (educationDetails?.carNumber ?? "");
        const icf = insuranceCompanies.filter((ic) => ic.slug === selectedInsuranceCompany);
        if (icf.length !== 0) {
            const emailAddress = icf[0].emailAddress;
            window.location.href = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        }
    };

    return (
        <div className={"w-full flex flex-col gap-4"}>
            <div>
                <p className={"text-sm"}>Asigurator selectat:</p>
                <select
                    value={selectedInsuranceCompany ?? 'default'}
                    onChange={handleProviderUiChange}
                    className="appearance-none w-full bg-white border border-accent-main-500 px-4 py-2 rounded-lg outline-none"
                >
                    <option value="default" disabled>Selecteaza un asigurator
                    </option>
                    {insuranceCompanies.map((insuranceCompany) => (
                        <option
                            key={insuranceCompany.slug}
                            value={insuranceCompany.slug}
                        >
                            {insuranceCompany.name}
                        </option>
                    ))}
                </select>
            </div>
            {
                selectedInsuranceCompany == null
                    ? <></>
                    : insuranceCompanies.filter((ic) => ic.slug === selectedInsuranceCompany)[0].phoneNumber != null
                        ?
                        <div>
                            <p className={"text-md"}>
                                Numar de
                                telefon: {insuranceCompanies.filter((ic) => ic.slug === selectedInsuranceCompany)[0].phoneNumber}
                            </p>
                            {
                                insuranceCompanies.filter((ic) => ic.slug === selectedInsuranceCompany)[0].phoneDirections == null
                                    ? <p className={"text-md"}> Persoana contact direct</p>
                                    : <p className={"text-md"}>
                                        Indicatii meniu
                                        telefonic: {insuranceCompanies.filter((ic) => ic.slug === selectedInsuranceCompany)[0].phoneDirections}
                                    </p>
                            }
                            <p>Email: {insuranceCompanies.filter((ic) => ic.slug === selectedInsuranceCompany)[0].emailAddress ?? "-"}</p>
                        </div>
                        : <></>
            }
            <div>
                <p className={"text-sm"}>Numarul de inmatriculare:</p>
                <input type={"text"}
                       name={"carNumber"}
                       onChange={handleEducationDetailsChange}
                       value={educationDetails?.carNumber ?? ""}
                       className={"p-2 outline-none w-full border border-accent-main-500 rounded-lg"}/>
            </div>
            <div>
                <p className={"text-sm"}>Suma de bani asigurata:</p>
                <input type={"number"}
                       name={"amount"}
                       step={0.01}
                       onChange={handleEducationDetailsChange}
                       value={educationDetails?.amount ?? ""}
                       className={"p-2 outline-none w-full border border-accent-main-500 rounded-lg"}/>
            </div>
            <div className={"flex items-center"}>
                <input
                    type={"checkbox"}
                    name={"adr"}
                    checked={educationDetails?.adr ?? false}
                    onChange={(e) => handleCheckState(e.target.name, e.target.checked)}
                    className={"w-5 h-5 accent-blue-500"}
                />
                <label htmlFor={"adr"} className={"ml-2 text-sm text-gray-700"}>
                    Este marfa ADR?
                </label>
            </div>
            <div className={"flex items-center"}>
                <input
                    type={"checkbox"}
                    name={"agabaritic"}
                    checked={educationDetails?.agabaritic ?? false}
                    onChange={(e) => handleCheckState(e.target.name, e.target.checked)}
                    className={"w-5 h-5 accent-blue-500"}
                />
                <label htmlFor={"agabaritic"} className={"ml-2 text-sm text-gray-700"}>
                    Este marfa agabaritica?
                </label>
            </div>
            <div className={"flex items-center"}>
                <input
                    type={"checkbox"}
                    name={"frigo"}
                    checked={educationDetails?.frigo ?? false}
                    onChange={(e) => handleCheckState(e.target.name, e.target.checked)}
                    className={"w-5 h-5 accent-blue-500"}
                />
                <label htmlFor={"frigo"} className={"ml-2 text-sm text-gray-700"}>
                    Este marfa frigorifica?
                </label>
            </div>
            <button onClick={handleSendEmail}
                    className={"w-full p-2 text-center bg-accent-main-500 text-white rounded-lg"}>
                Trimite e-mail
            </button>
        </div>
    );
}